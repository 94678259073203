<script setup>
import { useDebounceFn } from '@vueuse/core'
import { reactive, ref, watchEffect } from 'vue'
import prefix_email from '@/assets/prefix_email.svg'
import { validateEmail } from '../utils.js'
import ForgotSecond from './forgot-second.vue'
import { useRoute } from 'vue-router'
import { resetPwd } from './config'
import { useUserStore } from '@/store/user.js'
const route = useRoute()

const currentModa = ref("forgot")
watchEffect(
  () => {
    currentModa.value = route.query.type
  }
)
const isEmail = ref(false)
const formState = reactive({
  email: '',
});

const step = ref(1)
const loading = ref(false)
const user = useUserStore()
const debouncedFn = useDebounceFn(async (value) => {
  //api
  user.help(value).then(({ code, msg }) => {
    loading.value = false;
    step.value = 2;
  }).catch(() => {
    loading.value = false;
  });
}, 500);

const onFinish = values => {
  loading.value = true;
  debouncedFn(values);
};

const onFinishFailed = errorInfo => {
  console.log('Failed:', errorInfo);
  loading.value = false;
};

const handleEmailChange = () => {
  isEmail.value = validateEmail(formState.email);
};
</script>

<template>
  <div>
    <slot></slot>
    <h3 class="text-4xl font-medium mb-8">{{ resetPwd[`${currentModa}`].title }}</h3>
    <a-form v-if="step === 1" :model="formState" class="login-form " name="basic" autocomplete="off" @finish="onFinish"
      @finishFailed="onFinishFailed">
      <a-form-item>
        <!-- TODO 重置和忘记密码展示不同的信息 -->
        <div class="text-left mb-8 forgot-text ">{{ resetPwd[`${currentModa}`].describe }}</div>
      </a-form-item>
      <a-form-item name="email" class="mb-8">
        <a-input v-model:value="formState.email" @change="handleEmailChange" class="form-input" placeholder="Email">
          <template #prefix>
            <img :src="prefix_email" />
          </template>
        </a-input>
      </a-form-item>

      <a-form-item>
        <a-button type="primary" html-type="submit" :disabled="!isEmail" :loading="loading" class="form-button">{{
          resetPwd[`${currentModa}`].button
        }}</a-button>
      </a-form-item>
    </a-form>
    <ForgotSecond v-if="step === 2" />
  </div>
</template>

<style lang="less" scoped>
.forgot-text {
  width: 100%;
  text-align: center;
}

.login-form {
  min-width: 390px;
  max-width: 580px;
  padding-inline: 24px;
}

.form-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.form-button, .form-input {
  width: 100%;
  min-width: auto;
  max-width: 375px;
}

:global(.form-button .ant-btn-loading-icon) {
  display: flex;
}

@screen s {
  .forgot > h3 {
    font-size: 28px;
  }
}
</style>