<script setup>
import { useDebounceFn } from '@vueuse/core'
import { reactive, ref } from 'vue'
import prefix_password from '@/assets/prefix_password.svg'
import { checkIsPassword } from '../utils.js'
import ForgotFour from './forgot-four.vue'
import { useUserStore } from '@/store/user.js'
import { useRoute } from 'vue-router'
const formState = reactive({
  password: '',
  rePassword: '',
  passwordStatus: "",
  rePasswordStatus: "",
  errorMsg: "",
});

const step = ref(3)
const isCanReset = ref(false)
const user = useUserStore()
const route = useRoute()
const debouncedFn = useDebounceFn((value) => {
  //api
  console.log(value)
  const params = {
    email: route.query.email,
    state: route.query.token,
    password: formState.password
  }
  user.resetPwd(params)
  step.value = 4
}, 500)

const onFinish = values => {
  //此处做校验
  formState.passwordStatus = "",
    formState.rePasswordStatus = "",
    formState.errorMsg = "",
    checkForm(formState)
};


const checkForm = values => {
  //先校验是否符合密码规范
  const {
    password, rePassword
  } = values;

  if (password !== rePassword) {
    formState.rePasswordStatus = "error"
    formState.errorMsg = "Whoops. Passwords don't match. Try reentering them."
    return
  }

  if (!checkIsPassword(password)) {
    formState.passwordStatus = "error"
    formState.rePasswordStatus = "error"
    formState.errorMsg = "Password must be 6 to 32 characters long and must contain letters and numbers."
    return
  }
  debouncedFn(values)

}

const onFinishFailed = errorInfo => {
  console.log('Failed:', errorInfo);
};

const handlePasswordChange = () => {
  if (formState.password && formState.rePassword) {
    isCanReset.value = true
  }
}


</script>

<template>
  <div class="reset-form">
    <slot></slot>
    <h3 class="text-4xl font-medium mb-8">Reset Password</h3>
    <a-form v-if="step === 3" :model="formState" class="login-form " name="basic" autocomplete="off" @finish="onFinish"
      @finishFailed="onFinishFailed">
      <a-form-item>
        <div class="text-left mb-8 forgot-text ">Enter your new password. A lengthy mix of numbers, symbols, and letters
          of the uppercase and lowercase variety makes for the most secure password.</div>
      </a-form-item>
      <a-form-item name="email" class="mb-8" :validateStatus="formState.passwordStatus">
        <a-input-password v-model:value="formState.password" @change="handlePasswordChange" class="form-input"
          placeholder="New Password">
          <template #prefix>
            <img :src="prefix_password" />
          </template>
        </a-input-password>
      </a-form-item>
      <a-form-item name="new password" class="mb-8" :validateStatus="formState.rePasswordStatus">
        <a-input-password v-model:value="formState.rePassword" @change="handlePasswordChange" class="form-input"
          placeholder="Re-enter New Password">
          <template #prefix>
            <img :src="prefix_password" />
          </template>
        </a-input-password>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" html-type="submit" :disabled="!isCanReset" class="form-button">Reset</a-button>
      </a-form-item>
      <a-form-item>
        <div class="error-msg-box">{{ formState.errorMsg }}</div>
      </a-form-item>
    </a-form>
    <ForgotFour v-if="step === 4" />
  </div>
</template>

<style lang="less" scoped>
.login-form {
  min-width: 390px;
  max-width: 570px;
  padding-inline: 24px;
}

.forgot-text {
  width: 100%;
  text-align: center
}

.form-button, .form-input {
  width: 100%;
  min-width: auto;
  max-width: 375px;
}

:global(.reset-form .ant-form-item-control-input-content) {
  // text-align: center;
  display: flex;
  justify-content: center;
}

@screen s {
  .forgot > h3 {
    font-size: 28px;
  }
}
</style>