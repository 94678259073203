<script setup>
import { ref, computed, watchEffect, onBeforeMount, onBeforeUnmount } from 'vue'
import { useWindowSize } from '@vueuse/core'
import { useRouter } from 'vue-router'
import ForgotFirst from './forgot-first.vue'
import ForgotThree from './forgot-third.vue'
import { useRoute } from 'vue-router'
import { useViewStore } from '@/store'
import arrow_icon from '@/assets/arrow_icon.svg'
import { tailwindConfig as tailwindSharedConfig } from '@/shared.config.js'

const route = useRoute()
const router = useRouter()
const viewStore = useViewStore()
const containerHeight = ref(innerHeight)
const { width: containerWidth } = useWindowSize()
const isPC = computed(() => tailwindSharedConfig.screens.m.max < containerWidth.value)
const tab = {
  ForgotFirst,
  ForgotThree,
}
const currentTab = ref("ForgotFirst")
watchEffect(
  () => {
    currentTab.value = route.query.step === "1" ? "ForgotFirst" : "ForgotThree"
  }
)

function handleResize() {
  containerHeight.value = innerHeight
}

onBeforeMount(() => {
  // viewStore.setMinViewWidth('390px')
  window.addEventListener('resize', handleResize)
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize)
})
</script>

<template>
  <div class="flex flex-col" :style="{minHeight: `${containerHeight - .01}px`}">
    <component :is="tab[currentTab]" class="forgot" :style="{paddingBlock: isPC ? '160px' : '80px'}">
      <!-- <button v-if="!isPC" class="back-button" @click="router.back">
        <img :src="arrow_icon" height="18" style="margin-right: 16px"/>
        <span>Back</span>
      </button> -->
    </component>
  </div>
</template>

<style lang="less" scoped>
.forgot {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1 1 0%;
  min-width: 390px;
}

.back-button {
  position: absolute;
  top: 22.5px;
  left: min(5%, 24px);
  display: flex;
  align-items: center;
  width: 25px;
  height: 25px;
}

.back-button > span {
  font-size: 16px;
}
</style>